<template>
  <v-autocomplete
    :label="label"
    :value="current"
    :items="candidate"
    :loading="loading"
    item-value="departmentId"
    item-text="departmentName"
    return-object
    :disabled="disabled"
    :rules="rules"
    @change="onSelected"
    clearable
    hide-no-data
    filled
    dense
    :attach="attach"
  ></v-autocomplete>
</template>

<script>
export default {
  name: "DepartmentField",
  model: { prop: "value", event: "selected" },
  props: {
    label: { type: String, default: "部署名" },
    value: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    attach: { type: Boolean, default: true },
    rules: { type: Array, default: () => [] },
  },
  data() {
    return {
      loading: null,
      candidate: [],
      current: null,
    };
  },
  async mounted() {
    await this.setup();
    this.apply(this.value);
  },
  watch: {
    value(value) {
      this.apply(value);
    },
  },
  methods: {
    async setup() {
      try {
        this.loading = true;
        const response = await this.$store.dispatch("department/search");
        if (response.data?.contents?.departments) {
          this.candidate = response.data.contents.departments;
        } else {
          this.candidate = [];
        }
      } catch (error) {
        console.error("DepartmentField::setup", error);
      } finally {
        this.loading = false;
      }
    },
    apply(value) {
      if (value) {
        if (value == this.current?.departmentId) {
          // NOP
        } else {
          const current = this.candidate.find((c) => value === c.departmentId);
          this.onSelected(current);
        }
      } else {
        this.current = null;
      }
    },
    selected() {
      return this.current;
    },
    onSelected(value) {
      this.current = value;
      if (value) {
        this.$emit("selected", value.departmentId);
      } else {
        this.$emit("selected", null);
      }
    },
  },
};
</script>
